import React from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import { API, Auth } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import moment from 'moment-timezone';
import NativeSelect from '@material-ui/core/NativeSelect';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import '../../../../assets/css/main.css';
import CognitoErrors from '../../../../lib/cognitoErrors'
import CheckValue from '../../../../lib/formatValidations'
import DeafultSearch from '../../../common/deafultSearch'
import DefaultSelect from '../../../common/defaultSelect';
import mdiKey from '../../../../assets/images/mdi_key.svg'
import mdiEdit2 from '../../../../assets/images/mdi_edit2.svg'
import iconButtonPlus from '../../../../assets/images/icon_button_plus.png'
import countryCodes from '../../../../lib/countryCodes'
import {FONT_DEFAULT} from '../../../../lib/constants.js'
import awsBusiness from '../../../../aws-exports.js';

const awsmobile = awsBusiness;

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  };
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 10px 0px 10px',
  },
  tableContainer: {
    display: 'flex',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: '#F9F9F9',
    borderRadius: 5,
    flexDirection: "row"
  },
  buttonContainer: {
    display: 'flex',
    marginBottom: 25,
  },
  tableGridContainer: {
    width: 'calc(100vw - 87px)',
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 20,
    marginBottom: 20,

  },
  actionButonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: 40,
    alignItems: 'center',
    marginTop: 9

  },
  actionButon1: {
    cursor: 'pointer',
    marginRight: 10,

  },
  actionButon2: {
    cursor: 'pointer',
    marginLeft: 0
  },
  tableHeaderText: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #BDBDBD',
    borderBottom: '1px solid #BDBDBD',
    height: 60,
    justifyContent: 'center'
  },
  tableRowText1: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 12,
    paddingTop: 30,
    paddingBottom: 30,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    height: 40,
    textAlign: 'center',
    justifyContent: 'center'
  },
  tableRowText2: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: '600l',
    fontSize: 12,
    paddingTop: 30,
    paddingBottom: 30,
    display: 'flex',
    alignItems: 'center',
    height: 40,
    textAlign: 'center',
    justifyContent: 'center',
    backgroundColor: '#F9F9F9',
  },
  buttonStyle: {
    border: '1px solid #3B9A83',
    boxSizing: 'border-box',
    borderRadius: 5,
    width: 146,
    height: 38,
    margin: 2
  },
  buttonText: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 10,
    color: '#3B9A83'
  },
  plusIcon: {
    marginRight: 10
  },
  paper: {
    position: 'absolute',
    width: 497,
    height: 'auto',
    backgroundColor: '#F9F9F9',
    borderRadius: 0,
    textAlign: 'center',
  },
  paperPermissions: {
    position: 'absolute',
    width: 400,
    height: 'auto',
    backgroundColor: '#F9F9F9',
    borderRadius: 0
  },
  modalBackground: {
    zIndex: '-1',
    backgroundColor: 'rgb(0,0,0,0.1)'

  },
  mainContainerRegister: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: '1'
  },
  modalButtons: {
    marginBottom: 20,
    marginTop: 20
  },
  title: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 16,
    color: '#4F4F4F',
    marginTop: 20
  },
  titleIcon: {
    marginTop: 20,
    marginRight: 10
  },
  backgroundOddColumn: {
    backgroundColor: '#F9F9F9'
  },
  SubContainer: {
    width: 315,
    alignSelf: 'center'
  },
  labelnputTitle: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 14,
    color: '#434343',
    marginTop: 40
  },
  labelnputText: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 12,
    color: 'rgba(67, 67, 67, 0.6)'
  }
});

function TabEmpleados(props) {
  useEffect(() => {
    getEmployees()
  }, [])

  const classes = useStyles();

  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openResetPassword, setOpenResetPassword] = React.useState(false);
  const [openPermissions, setOpenPermissions] = React.useState(false);
  const [countryCode, setCountryCode] = React.useState('+52');

  const [isLoading, setLoading] = React.useState(true);
  const [employees, setEmployees] = React.useState([]);
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);
  const [name, setName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [isActive, setIsActive] = React.useState(true);
  const [isManager, setIsManager] = React.useState(false);
  const [isMerchant, setIsMerchant] = React.useState(false);
  const [currentManager, setCurrentManager] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmedpassword, setConfirmedpassword] = React.useState('');
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const [selectedRole, setSelectedRole] = React.useState('');

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };


  const getEmployees = async (unconfirmed = []) => {
    API.get('merchant-api', '/admin/employees', {
      headers:{
        Authorization: (await Auth.currentSession()).getAccessToken().getJwtToken()
      },
      queryStringParameters: {
        merchantRecordKey: props.merchantRecordKey
      },
    })
      .then(async result => {
        if (result.data !== undefined) {
          let employeeList = []
          if (unconfirmed.length > 0) {
            employeeList = unconfirmed.concat(result.data)
          } else {
            employeeList = result.data
          }
          function compare(a, b) {
            let weight = !a.isEmployeeActive && b.isEmployeeActive ?  0: -10;

            if (a.usertype === 'manager' && b.usertype !== 'manager')
              weight+= -1;
            if (a.usertype !== 'manager' && b.usertype === 'manager')
              weight+= 1;
            if (a.usertype !== 'merchant' && b.usertype === 'merchant')
              weight+= 2;
            return weight;
          }
          employeeList.sort(compare);
          setEmployees(employeeList)
          setLoading(false)
        } else {
          setLoading(false)
        }
      })
      .catch(err => {
        console.log('Error: ', err)
        setLoading(false)
      });
  }

  const handleOpen = () => {
    let root = document.getElementById("root");
    root.style.filter = "blur(3px)"
    setOpen(true);
  };

  const handleOpenEdit = (employee) => {
    setSelectedEmployee(employee.userRecordKey)
    setName(employee.firstName)
    setLastName(employee.lastName)
    setIsActive(employee.isEmployeeActive)
    setOpenEdit(true);
  };

  const handleOpenPermissions = (employee) => {
    let currentRole = 'Empleado'
    if (employee.usertype === 'manager') {
      currentRole = 'Gerente'
    }
    setSelectedEmployee(employee.userRecordKey)
    setName(employee.firstName)
    setLastName(employee.lastName)
    setSelectedRole(currentRole)
    setOpenPermissions(true);
  };

  const handleClose = () => {
    let root = document.getElementById("root");
    root.style.filter = "unset"
    setSelectedEmployee(null)
    setName('')
    setLastName('')
    setPhone('')
    setIsActive(true)
    setOpen(false);
    setOpenEdit(false);
    setOpenPermissions(false)
    setIsManager(false)
  };

  const handleOpenResetPassword = (employee) => {
    setSelectedEmployee(employee.userRecordKey)
    setOpenResetPassword(true);
  };

  const handleCloseResetPassword = async (response) => {
    setOpenResetPassword(false);
    if (response) {
      setLoading(true)
      try {
        await API.post('profile-api', `/profile/resetPassword/${selectedEmployee}`, {
          body: {
            userPool: awsmobile.aws_user_pools_id
          }
        });
        setLoading(false)
        handleAlert('success', 'Solicitud Exitosa')
      } catch (err) {
        handleAlert('error', err.response.data.error)
        setLoading(false)
      }
    }
    setSelectedEmployee(null)
  };

  const handleClickShowPassword = (number) => {
    if (number === 1) {
      setShowPassword1(!showPassword1)
    } else if (number === 2) {
      setShowPassword2(!showPassword2)
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const updateRol = async (e) => {
    setLoading(true)
    console.log('selectedEmployee->', selectedEmployee)
    console.log('selectedRole->', selectedRole)
    if (selectedRole === 'Mercante') {
      try {
        const resp = await API.post('merchant-api', '/admin/promote', {
           headers:{
            Authorization: (await Auth.currentSession()).getAccessToken().getJwtToken()
           },
            body: {
              userRecordKey: selectedEmployee,
              merchantRecordKey: props.merchantRecordKey,
              usertype: 'merchant'
            },
          });
        if(resp.success === false){
          handleAlert('warning', resp.error)
        }
      } catch (err) {
        console.error('Error promoviendo manager a mercante: ', err)
      };
    }
    else{
      handleAlert('info', 'Actualmente sólo la promoción de Gerente a Mercante está disponible en esta interfaz');
    }
    handleClose()
    getEmployees()
    setLoading(false)
  }

  const updateEmployee = async (e) => {
    setLoading(true)
    if (!CheckValue('empty', name)) {
      setLoading(false)
      handleAlert('error', 'El nombre es requerido')
      return;
    }
    if (!CheckValue('empty', lastName)) {
      setLoading(false)
      handleAlert('error', 'Los apellidos son requeridos')
      return;
    }

    const profileCREATE_NEW = await API.put('profile-api', `/profile/${selectedEmployee}`, {
      body: {
        firstName: name,
        lastName,
        isEmployeeActive: isActive
      },
    }).then(result => {
      handleClose()
      getEmployees()
      setLoading(false)
      handleAlert('success', 'Guardado con Éxito!')
    }).catch(err => { console.log(err); handleAlert('error', 'Error al Guardar') });
  }

  const _attempMerchantSingUp = async (e) => {
    setLoading(true)
    if (!CheckValue('empty', name)) {
      setLoading(false)
      handleAlert('error', 'El nombre es requerido')
      return;
    }
    if (!CheckValue('empty', lastName)) {
      setLoading(false)
      handleAlert('error', 'Los apellidos son requeridos')
      return;
    }
    if (!CheckValue('email', email)) {
      setLoading(false)
      handleAlert('error', 'Correo electrónico no válido')
      return;
    }
    if (password.includes(phone)) {
      setLoading(false);
      handleAlert('error', 'La contraseña no debe ser igual o incluir al número de teléfono')
      return;
    }
    if (password !== confirmedpassword) {
      setLoading(false)
      handleAlert('error', 'Contraseñas diferentes')
      return;
    } if (!CheckValue('password', password)) {
      setLoading(false);
      handleAlert('error', 'La contraseña debe tener mínimo 6 caracteres, incluyendo por lo menos un número y un caracter especial (!¡@#$%^&*(),.¿?":{}|<>_-)')
      return;
    } else if (password.toLowerCase().includes('nerito')) {
      this.setState({ isLoading: false });
      this.handleAlert('error', 'La contraseña no debe incluir "Nerito"');
      return;
    } else if (CheckValue('hasConsecutiveRepeat', password)) {
      this.setState({ isLoading: false });
      this.handleAlert('error', 'La contraseña no debe incluir el mismo caracter 3 veces consecutivas.');
      return;
    } else if (CheckValue('hasConsecutive', password)) {
      this.setState({ isLoading: false });
      this.handleAlert('error', 'La contraseña no debe incluir 3 caracteres consecutivos.');
      return;
    }
    try {
      let userType = 'employee@'
      if (isManager) {
        userType = 'manager@'
      }
      const signup = await Auth.signUp({
        username: countryCode + phone.trim(),
        password,
        attributes: {
          'custom:usertype': userType + JSON.stringify({ store: props.currentStore.id, merchant: props.currentStore.merchant, createdat: props.currentStore.createdat }),
          'custom:firstName': name,
          'custom:lastName': lastName
        },
        validationData: []
      });
      if (signup.user.username === countryCode + phone.trim()) {
        console.log('Sub->', signup.userSub)
        try {
          await API.post('profile-api', `/profile/addEmail/${signup.userSub}`, {
            body: {
              userPool: awsmobile.aws_user_pools_id,
              email
            }
          });
        } catch (err) {
          handleAlert('error', err.response.data.error)
        }
        let employees = []
        if (props.currentStore.employees) {
          employees = props.currentStore.employees
        }
        employees.push({ firstName: name, lastName, phone: countryCode + phone.trim() })
        await API.put('store-api', `/store/${props.currentStore.id}`, {
          body: {
            merchant: props.currentStore.merchant,
            createdat: props.currentStore.createdat,
            employees
          },
        });
        handleClose()
        getEmployees(employees)
        setLoading(false)
        handleAlert('success', 'Empleado Registrado con Éxito!')
      }
    } catch (error) {
      setLoading(false)
      console.log('SIGNUP-ERROR', error);
      handleAlert('error', CognitoErrors(error.message))
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "selectedRole":
        setSelectedRole(value);
        break;
      default:
        console.log("Input no reconocido", name);
    }
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className={classes.mainContainerRegister}>
        <div className="SubContainer">
          <Typography className={classes.title}>
            Nuevo empleado
            </Typography>
          <FormControl className="inputForm col-12 col-sm-12 col-lg-12 col-xl-12">
            <InputLabel className="labelInputStyle">Nombre(s)</InputLabel>
            <Input
              id="name"
              type="text"
              name="name"
              className="defaultInputText"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </FormControl>
          <FormControl className="inputForm col-10 col-sm-12 col-lg-12 col-xl-12">
            <InputLabel className="labelInputStyle">Apellidos</InputLabel>
            <Input
              id="lastName"
              type="text"
              name="lastName"
              className="defaultInputText"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />
          </FormControl>
          <FormControl className="inputForm col-10 col-sm-12 col-lg-12 col-xl-12">
            <InputLabel className="labelInputStyle">Correo Electrónico</InputLabel>
            <Input
              id="email"
              type="text"
              name="email"
              className="defaultInputText"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </FormControl>
          <FormControl className="inputFormCountryCode col-2 col-sm-2 col-lg-2 col-xl-2">
            <NativeSelect
              value={countryCode}
              onChange={(event) => setCountryCode(event.target.value)}
              name="countryCode"
              className={'countryCodeSelect'}
              inputProps={{ 'aria-label': 'age' }}
            >
              {countryCodes().map(code => {
                return <option value={code.value}>{code.label}</option>
              })}
            </NativeSelect>
          </FormControl>
          <FormControl className="inputForm col-10 col-sm-10 col-lg-10 col-xl-10">
            <InputLabel className="labelInputStyle">Número de Teléfono</InputLabel>
            <Input
              id="phone"
              type="text"
              name="phone"
              className="defaultInputPhone"
              placeholder={countryCode}
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
            />
          </FormControl>
          <FormControl className="inputForm col-12 col-sm-12 col-lg-12 col-xl-12">
            <InputLabel className="labelInputStyle">Contraseña Temporal</InputLabel>
            <Input
              id="pwd"
              type={showPassword1 ? 'text' : 'password'}
              name="password"
              className="defaultInputPassword"
              placeholder="******"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => handleClickShowPassword(1, e)}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword1 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl className="inputForm col-12 col-sm-12 col-lg-12 col-xl-12">
            <InputLabel className="labelInputStyle">Confirmar Contraseña Temporal</InputLabel>
            <Input
              id="cpwd"
              type={showPassword2 ? 'text' : 'password'}
              name="confirmedpassword"
              className="defaultInputPassword"
              placeholder="******"
              value={confirmedpassword}
              onChange={(event) => setConfirmedpassword(event.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => handleClickShowPassword(2, e)}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword2 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {isMerchant && <FormControlLabel
            control={
              <Checkbox
                checked={isManager}
                onChange={(event) => setIsManager(!isManager)}
                color="#6EB89D"
              />
            }
          />
          }
        </div>
      </div>
    </div>
  );

  const bodyEdit = (
    <div style={modalStyle} className={classes.paper}>
      <div className={classes.mainContainerRegister}>
        <div className="SubContainer">
          <Typography className={classes.title}>
            Editar empleado
            </Typography>
          <FormControl className="inputForm">
            <InputLabel className="labelInputStyle">Nombre(s)</InputLabel>
            <Input
              id="name"
              type="text"
              name="name"
              className="defaultInputText"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </FormControl>
          <FormControl className="inputForm">
            <InputLabel className="labelInputStyle">Apellidos</InputLabel>
            <Input
              id="lastName"
              type="text"
              name="lastName"
              className="defaultInputText"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={isActive}
                onChange={(event) => setIsActive(!isActive)}
                color="#6EB89D"
              />
            }
            label="Activo"
            className={classes.checkLabel}
          />
          <div className={classes.modalButtons}>
            {!isLoading ?
              <>
                <Button className={classes.buttonStyle} onClick={updateEmployee}>
                  <Typography className={classes.buttonText}>Guardar</Typography>
                </Button>
                <Button className={classes.buttonStyle} onClick={handleClose}>
                  <Typography className={classes.buttonText}>Cancelar</Typography>
                </Button>
              </>
              :
              <>
                <Button className={classes.buttonStyle} disabled={true}>
                  <Typography className={classes.buttonText}>Guardando...</Typography>
                </Button>
                <Button className={classes.buttonStyle} disabled={true}>
                  <Typography className={classes.buttonText}>Cancelar</Typography>
                </Button>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );

  const bodypermissions = (
    <div style={modalStyle} className={classes.paperPermissions}>
      <div className={classes.mainContainerRegister}>
        <div className={classes.SubContainer}>
          <div className="row">
            <img width="23" height="23" src={mdiKey} className={classes.titleIcon} />
            <Typography className={classes.title}>
              Permisos
              </Typography>
          </div>
          <FormControl className="inputForm">
            <Input
              id="name"
              type="text"
              name="name"
              className="defaultInputText"
              value={`${name} ${lastName}`}
              disabled={true}
            />
          </FormControl>
          <Typography className={classes.labelnputTitle}>
            Rol
            </Typography>
          <Typography className={classes.labelnputText}>
            Selecciona el rol de este empleado
            </Typography>
          <DefaultSelect
            custom={{
              handleChange,
              value: selectedRole,
              classForm: "inputForm col-12",
              classLabel: "labelInputStyleForm",
              id: "selectedRole",
              name: "selectedRole",
              className: "defaultInputTextForm",
              items: ['Mercante', 'Gerente', 'Empleado']
            }}
          />
          <Typography className={classes.labelnputTitle}>
            Permisos / bloquear
            </Typography>
          <Typography className={classes.labelnputText}>
            Al seleccionar este permiso el usuario tendrá acceso a:
            </Typography>
          <div className="row">
            <FormControlLabel
              control={
                <Checkbox
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleChecked />}
                  checked={true}
                  color="rgba(67, 67, 67, 0.6)"
                  disabled={true}
                />
              }
              label="App móvil"
              labelPlacement="start"
              className={classes.checkLabel}
            />
            <FormControlLabel
              control={
                <Checkbox
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleChecked />}
                  checked={selectedRole !== 'Empleado'}
                  color="rgba(67, 67, 67, 0.6)"
                  disabled={true}
                />
              }
              label="App web"
              labelPlacement="start"
              className={classes.checkLabel}
            />
          </div>
          <div className={classes.modalButtons}>
            {!isLoading ?
              <>
                <Button className={classes.buttonStyle} onClick={updateRol}>
                  <Typography className={classes.buttonText}>Guardar</Typography>
                </Button>
                <Button className={classes.buttonStyle} onClick={handleClose}>
                  <Typography className={classes.buttonText}>Cancelar</Typography>
                </Button>
              </>
              :
              <>
                <Button className={classes.buttonStyle} disabled={true}>
                  <Typography className={classes.buttonText}>Guardando...</Typography>
                </Button>
                <Button className={classes.buttonStyle} disabled={true}>
                  <Typography className={classes.buttonText}>Cancelar</Typography>
                </Button>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      {isLoading ?
        <div className='gralinfo_form'>
          <CircularProgress color="secondary" />
        </div>
        :
        <div className={classes.container}>
          <div className={classes.buttonContainer}>
            <DeafultSearch label="Buscar por empleado" />
            <Button className={classes.buttonStyle}>
              <img width="8" height="8" src={iconButtonPlus} className={classes.plusIcon} />
              <Typography className={classes.buttonText} onClick={handleOpen}>Nuevo Empleado</Typography>
            </Button>
          </div>
          <div className="mt-3 overflow-auto">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <Typography className={classes.tableHeaderText}>Nombre</Typography>
                  </th>
                  <th>
                    <Typography className={classes.tableHeaderText}>Teléfono</Typography>
                  </th>
                  <th>
                    <Typography className={classes.tableHeaderText}>Rol</Typography>
                  </th>
                  <th>
                    <Typography className={classes.tableHeaderText}>Última Conexión</Typography>
                  </th>
                  <th>
                    <Typography className={classes.tableHeaderText}>Estatus</Typography>
                  </th>
                  <th>
                    <Typography className={classes.tableHeaderText}>Acciones</Typography>
                  </th>
                </tr>
              </thead>
              <tbody>
                {employees.map(employee =>
                  <tr key={employee.no} item lg={12}>
                    <td className={classes.backgrounOddColumn}>
                      <Typography className={classes.tableRowText2} noWrap={true}>{employee.firstName} {employee.lastName}</Typography>
                    </td>
                    <td>
                      <Typography className={classes.tableRowText1} noWrap={true}>{employee.phoneNumber ? employee.phoneNumber : employee.phone}</Typography>
                    </td>
                    <td>
                      <Typography className={classes.tableRowText1} noWrap={true}>{employee.usertype === 'manager' ? 'Gerente' : employee.usertype === 'merchant'? 'Mercante': 'Empleado'}</Typography>
                    </td>
                    <td className={classes.backgrounOddColumn}>
                      <Typography className={classes.tableRowText2} noWrap={true}>{employee.lastLogin ? moment(employee.lastLogin).format('DD/MM/YYYY h:mm:ss a') : ''}</Typography>
                    </td>
                    <td>
                      <Typography className={classes.tableRowText1}>{employee.isEmployeeActive ? 'Activo' : 'Inactivo'}</Typography>
                    </td>
                    <td className={classes.backgrounOddColumn}>
                      {employee.phoneNumber && <div className={classes.actionButonsContainer}>
                        <img width="23" height="23" src={mdiKey} className={classes.actionButon1} onClick={() => handleOpenPermissions(employee)} />
                        {currentManager !== employee.userRecordKey && <img width="23" height="23" src={mdiEdit2} className={classes.actionButon2} onClick={() => handleOpenEdit(employee)} />}
                      </div>}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      }
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <Modal
        open={openEdit}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyEdit}
      </Modal>
      <Modal
        open={openPermissions}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodypermissions}
      </Modal>
      <Dialog
        open={openResetPassword}
        onClose={() => handleCloseResetPassword(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Desea restablecer la contraseña de este empleado?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            El empleado recibirá un código en su correo electrónico y deberá introducirlo para cambiar su contraseña la próxima vez que inicie sesión.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseResetPassword(false)} color="default">
            Cancelar
            </Button>
          <Button onClick={() => handleCloseResetPassword(true)} color="default" className="outlinedButton" autoFocus>
            Continuar
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withSnackbar(TabEmpleados)