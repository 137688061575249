import React from 'react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {
  Typography, Grid, CircularProgress, Button, Modal, IconButton, Input,
  InputLabel, FormControl, InputAdornment, FormControlLabel, Checkbox, NativeSelect, Dialog,
  DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@material-ui/core';
import { API, Auth } from 'aws-amplify';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import moment from 'moment-timezone';
import '../../../assets/css/main.css';
import CognitoErrors from '../../../lib/cognitoErrors';
import CheckValue from '../../../lib/formatValidations';
import mdiKey from '../../../assets/images/mdi_key.svg';
import mdiEdit from '../../../assets/images/editButton.svg';
import countryCodes from '../../../lib/countryCodes';
import noEmployees from '../../../assets/images/noEmployees.svg';
import PrimaryButton from '../../common/primaryButton';
import PrimaryInput from '../../common/primaryInput';
import PrimarySearchBar from '../../common/primarySearchBar';
import PrimarySelect from '../../common/primarySelect';
import awsBusiness from '../../../aws-exports';

const awsmobile = awsBusiness;

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  };
}

function Tab4(props) {
  const userKey = useSelector(state => state.user.value);

  useEffect(() => {
    getEmployees();
  }, []);

  const classes = useStyles();

  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openResetPassword, setOpenResetPassword] = React.useState(false);
  const [countryCode, setCountryCode] = React.useState('+52');

  const [isLoading, setLoading] = React.useState(true);
  const [employees, setEmployees] = React.useState([]);
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);
  const [name, setName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [isActive, setIsActive] = React.useState(true);
  const [isManager, setIsManager] = React.useState(false);
  const [isMerchant, setIsMerchant] = React.useState(false);
  const [currentManager, setCurrentManager] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmedpassword, setConfirmedpassword] = React.useState('');
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const getEmployees = async (unconfirmed = []) => {
    const getUser = await Auth.currentAuthenticatedUser();
    try {
      const getGralProfile = await API.get(
        'profile-api',
        `/profile/${userKey}`
      );
      if (getGralProfile.data[0].merchantRecordKey) {
        setIsMerchant(true);
      } else {
        setCurrentManager(userKey);
      }
    } catch (err) {
      handleAlert('Error', err);
    }
    API.get('profile-api', '/profile', { // esto cambio de mandar querys desde el front a estar en backend
      queryStringParameters: {
        storeRecordKey: props.currentStore.id,
      },
    })
      .then(async (result) => {
        if (result.data !== undefined) {
          if (unconfirmed.length === 0 && props.currentStore.employees) {
            unconfirmed = props.currentStore.employees;
          }
          let employeeList = [];
          if (unconfirmed.length > 0) {
            employeeList = unconfirmed.concat(result.data);
          } else {
            employeeList = result.data;
          }
          function compare(a, b) {
            if (a.usertype === 'manager' && b.usertype !== 'manager') return -1;
            if (a.usertype !== 'manager' && b.usertype === 'manager') return 1;
            return 0;
          }
          employeeList.sort(compare);
          let uniqueData = employeeList.filter((entry, index, self) =>
            index === self.findIndex((t) => (
              t.email === entry.email  
            )));
          setEmployees(employeeList);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenEdit = (employee) => {
    setSelectedEmployee(employee.userRecordKey);
    setName(employee.firstName);
    setLastName(employee.lastName);
    setIsActive(employee.isEmployeeActive);
    setOpenEdit(true);
  };

  const handleClose = () => {
    let root = document.getElementById('root');
    root.style.filter = 'unset';
    setSelectedEmployee(null);
    setName('');
    setLastName('');
    setPhone('');
    setIsActive(true);
    setOpen(false);
    setOpenEdit(false);
    setIsManager(false);
  };

  const handleOpenResetPassword = (employee) => {
    setSelectedEmployee(employee.userRecordKey);
    setOpenResetPassword(true);
  };

  const handleCloseResetPassword = async (response) => {
    setOpenResetPassword(false);
    if (response) {
      setLoading(true);
      try {
        await API.post(
          'profile-api',
          `/profile/resetPassword/${selectedEmployee}`,
          {
            body: {
              userPool: awsmobile.aws_user_pools_id,
            },
          }
        );
        setLoading(false);
        handleAlert('success', 'Solicitud Exitosa');
      } catch (err) {
        handleAlert('error', err.response.data.error);
        setLoading(false);
      }
    }
    setSelectedEmployee(null);
  };

  const handleClickShowPassword = (number) => {
    if (number === 1) {
      setShowPassword1(!showPassword1);
    } else if (number === 2) {
      setShowPassword2(!showPassword2);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const updateEmployee = async (e) => {
    setLoading(true);
    if (!CheckValue('empty', name)) {
      setLoading(false);
      handleAlert('error', 'El nombre es requerido');
      return;
    }
    if (!CheckValue('empty', lastName)) {
      setLoading(false);
      handleAlert('error', 'Los apellidos son requeridos');
      return;
    }

    const profileCREATE_NEW = await API.put(
      'profile-api',
      `/profile/${selectedEmployee}`,
      {
        body: {
          firstName: name,
          lastName,
          isEmployeeActive: isActive,
        },
      }
    )
      .then((result) => {
        handleClose();
        getEmployees();
        setLoading(false);
        handleAlert('success', 'Guardado con Éxito!');
      })
      .catch((err) => {
        handleAlert('error', 'Ah ocurrido un error al guardar este empleado');
      });
  };

  const createEmployee = async (e) => {
    setLoading(true);
    if (!CheckValue('empty', name)) {
      setLoading(false);
      handleAlert('error', 'El nombre es requerido');
      return;
    }
    if (!CheckValue('empty', lastName)) {
      setLoading(false);
      handleAlert('error', 'Los apellidos son requeridos');
      return;
    }
    if (!CheckValue('email', email)) {
      setLoading(false);
      handleAlert('error', 'Correo electrónico no válido');
      return;
    }
    if (password.includes(phone)) {
      setLoading(false);
      handleAlert(
        'error',
        'La contraseña no debe ser igual o incluir al número de teléfono'
      );
      return;
    }
    if (password !== confirmedpassword) {
      setLoading(false);
      handleAlert('error', 'Contraseñas diferentes');
      return;
    }
    if (!CheckValue('password', password)) {
      setLoading(false);
      handleAlert(
        'error',
        'La contraseña debe tener mínimo 6 caracteres, un caracter alfanumérico (A-Z,a-z,0-9) y un caracter especial (@$!%*?&)'
      );
      return;
    } else if (password.toLowerCase().includes('nerito')) {
      setLoading(false);
      handleAlert('error', 'La contraseña no debe incluir "Nerito"');
      return;
    } else if (CheckValue('hasConsecutiveRepeat', password)) {
      setLoading(false);
      handleAlert(
        'error',
        'La contraseña no debe incluir el mismo caracter 3 veces consecutivas.'
      );
      return;
    } else if (CheckValue('hasConsecutive', password)) {
      setLoading(false);
      handleAlert(
        'error',
        'La contraseña no debe incluir 3 caracteres consecutivos.'
      );
      return;
    }
    try {
      let userType = 'employee@';
      if (isManager) {
        userType = 'manager@';
      }
      const signup = await Auth.signUp({
        username: email,
        password,
        attributes: {
          phone_number: countryCode + phone.trim(),
          'custom:usertype':
            userType +
            JSON.stringify({
              store: props.currentStore.id,
              merchant: props.currentStore.merchant,
              createdat: props.currentStore.createdat,
            }),
          'custom:firstName': name,
          'custom:lastName': lastName,
          'custom:temporaryPassword': password,
        },
        validationData: [],
      });

      if (signup.user.username === email) {
        let employees = [];
        if (props.currentStore.employees) {
          employees = props.currentStore.employees;
        }
        employees.push({
          firstName: name,
          lastName,
          phone: countryCode + phone.trim(),
          email,
          usertype: userType.replace("@", "")
        });
        await API.put('store-api', `/store/${props.currentStore.id}`, {
          body: {
            merchant: props.currentStore.merchant,
            createdat: props.currentStore.createdat,
            employees,
          },
        });
        setEmail('');
        setPassword('');
        setConfirmedpassword('');
        handleClose();
        getEmployees(employees);
        setLoading(false);
        handleAlert('success', 'Empleado Registrado con Éxito!');
      }
    } catch (error) {
      setLoading(false);
      handleAlert('error', CognitoErrors(error.message));
    }
  };
  const handleProblem = async (employee) => {
    const description = employee
      ? 'Se tiene un problema con el empleado ' +
      name +
      ' ' +
      lastName +
      ' con clave ' +
      selectedEmployee
      : 'No se encuentran los empleados';
    const formData = new FormData();
    formData.append('name', props.currentStore.storeName);
    formData.append('merchantID', props.currentStore.merchant);
    formData.append('problemType', 'Empleados');
    formData.append('problem', description);
    formData.append('email', props.email);
    fetch(process.env.REACT_APP_ZAPIER_ZOHO, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        handleAlert('success', 'Tu problema se envió correctamente');
      })
      .catch((error) => {
        handleAlert('error', error.response.data.error);
      });
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className={classes.mainContainerRegister}>
        <div className='SubContainer'>
          <Typography className={classes.title}>Nuevo empleado</Typography>
          <FormControl className='inputForm col-12 col-sm-12 col-lg-12 col-xl-12'>
          </FormControl>
          <PrimaryInput
            id='name'
            type='text'
            name='name'
            className='defaultInputText'
            inputProps={{ maxLength: 40 }}
            value={name}
            onChange={(event) => setName(event.target.value)}
            placeholder='Nombre(s)'
          />
          <PrimaryInput
            id='lastName'
            type='text'
            name='lastName'
            className='defaultInputText'
            inputProps={{ maxLength: 40 }}
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
            placeholder='Apellidos'
          />
          <PrimaryInput
            id='email'
            type='text'
            name='email'
            inputProps={{ maxLength: 40 }}
            className='defaultInputText'
            value={email}
            placeholder='Correo Electrónico'
            onChange={(event) => setEmail(event.target.value)}
          />
          <Typography className={classes.textField}  >Número de Teléfono</Typography>
          <FormControl className=' col-2 col-sm-2 col-lg-2 col-xl-2'>
            <PrimarySelect
              value={countryCode}
              onChange={(event) => setCountryCode(event.target.value)}
              name='countryCode'
              className={'countryCodeSelect'}
              inputProps={{ 'aria-label': 'age' }}

              content={countryCodes().map((item) => {
                return <option value={item.value}>{item.label}</option>;
              })}
            />
          </FormControl>
          <FormControl className=' col-10 col-sm-10 col-lg-10 col-xl-10'>
            <PrimaryInput
              id='phone'
              type='text'
              name='phone'
              className='defaultInputPhone'
              placeholder={countryCode}
              value={phone}
              inputProps={{ maxLength: 10 }}
              onChange={(event) => setPhone(event.target.value)}
            />
          </FormControl>
          <FormControl className='inputForm col-12 col-sm-12 col-lg-12 col-xl-12'>
            <Typography className={classes.textField}  >Contraseña Temporal</Typography>
            <PrimaryInput
              id='pwd'
              type={showPassword1 ? 'text' : 'password'}
              name='password'
              className='defaultInputPassword'
              placeholder='******'
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={(e) => handleClickShowPassword(1, e)}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword1 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl className='inputForm col-12 col-sm-12 col-lg-12 col-xl-12'>
            <Typography className={classes.textField}  >Confirmar Contraseña Temporal</Typography>
            <PrimaryInput
              id='cpwd'
              type={showPassword2 ? 'text' : 'password'}
              name='confirmedpassword'
              className='defaultInputPassword'
              placeholder='******'
              value={confirmedpassword}
              onChange={(event) => setConfirmedpassword(event.target.value)}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={(e) => handleClickShowPassword(2, e)}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword2 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {isMerchant && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={isManager}
                  onChange={(event) => setIsManager(!isManager)}
                  color='#6EB89D'
                />
              }
              label='Gerente'
              className={classes.checkLabel}
            />
          )}
          <div className={classes.modalButtons}>
            {!isLoading ? (
              <>
                <Button className={classes.cancelButtonStyle} onClick={() => handleClose()}>
                  <Typography className={classes.cancelButtonText}>Cancelar</Typography>
                </Button>
                <Button
                  className={classes.crearButtonStyle}
                  onClick={createEmployee}>
                  <Typography className={classes.crearButtonText}>Crear</Typography>
                </Button>
              </>
            ) : (
              <>
                <Button className={classes.buttonStyle} disabled={true}>
                  <Typography className={classes.buttonText}>
                    Cancelar
                  </Typography>
                </Button>
                <Button className={classes.buttonStyle} disabled={true}>
                  <Typography className={classes.buttonText}>
                    Creando...
                  </Typography>
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const bodyEdit = (
    <div style={modalStyle} className={classes.paper}>
      <div className={classes.mainContainerRegister}>
        <div className='SubContainer'>
          <Typography className={classes.title}>Editar empleado</Typography>
          <FormControl className='inputForm'>
            <InputLabel className='labelInputStyle'>Nombre(s)</InputLabel>
            <Input
              id='name'
              type='text'
              name='name'
              inputProps={{ maxLength: 40 }}
              className='defaultInputText'
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </FormControl>
          <FormControl className='inputForm'>
            <InputLabel className='labelInputStyle'>Apellidos</InputLabel>
            <Input
              id='lastName'
              type='text'
              inputProps={{ maxLength: 40 }}
              name='lastName'
              className='defaultInputText'
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={isActive}
                onChange={(event) => setIsActive(!isActive)}
                color='#6EB89D'
              />
            }
            label='Activo'
            className={classes.checkLabel}
          />
          <div className={classes.modalButtons}>
            {!isLoading ? (
              <>
                <Button className={classes.buttonStyle} onClick={handleClose}>
                  <Typography className={classes.buttonText}>
                    Cancelar
                  </Typography>
                </Button>
                <Button
                  className={classes.buttonStyle}
                  onClick={updateEmployee}
                >
                  <Typography className={classes.buttonText}>
                    Guardar
                  </Typography>
                </Button>
              </>
            ) : (
              <>
                <Button className={classes.buttonStyle} disabled={true}>
                  <Typography className={classes.buttonText}>
                    Guardando...
                  </Typography>
                </Button>
                <Button className={classes.buttonStyle} disabled={true}>
                  <Typography className={classes.buttonText}>
                    Cancelar
                  </Typography>
                </Button>
              </>
            )}
          </div>
          {props.from ? (
            <div>
              <Button
                className={classes.buttonStyle}
                onClick={() => handleProblem(true)}
              >
                <Typography className={classes.redText}>
                  Tengo un problema con este empleado
                </Typography>
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      {isLoading ? (
        <div className='gralinfo_form'>
          <CircularProgress color='secondary' />
        </div>
      ) : (
        <div className={classes.container}>
          <Grid container >
            <Grid item xs={12} md={2}>
              <PrimaryButton onClick={handleOpen} fullWidth>
                {/* <div className={classes.center}>
                    <img width="18" height="18" src={iconButtonPlus} className={classes.plusIcon} />
                  </div> */}
                Nuevo Empleado
              </PrimaryButton>

            </Grid>
            <Grid item xs={12} md={8}>
              <PrimarySearchBar placeholder='Buscar empleado' fullWidth />
            </Grid>
          </Grid>
          {props.from ? (
            <div>
              <Button
                className={classes.buttonStyle}
                onClick={() => handleProblem(false)}
              >
                <Typography className={classes.redText}>
                  No encuentro a mi empleado
                </Typography>
              </Button>
            </div>
          ) : null}

          {employees.length > 0 ? (
            <div className='mt-3 overflow-auto'>
              <table className='table mt-4'>
                <thead>
                  <tr>
                    <th>
                      <Typography className={classes.tableHeaderText}>
                        Nombre
                      </Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>
                        Email
                      </Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>
                        Teléfono
                      </Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>
                        Rol
                      </Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>
                        Última Conexión
                      </Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>
                        Estatus
                      </Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>
                        Acciones
                      </Typography>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {employees.map((employee) => (
                    <tr key={employee.no} item lg={12}>
                      <td className={classes.backgrounOddColumn}>
                        <Typography
                          className={classes.tableRowText2}
                          noWrap={true}
                        >
                          {employee.firstName} {employee.lastName}
                        </Typography>
                      </td>
                      <td>
                        <Typography
                          className={classes.tableRowText1}
                          noWrap={true}
                        >
                          {employee.email}
                        </Typography>
                      </td>
                      <td>
                        <Typography
                          className={classes.tableRowText1}
                          noWrap={true}
                        >
                          {employee.phoneNumber
                            ? employee.phoneNumber
                            : employee.phone}
                        </Typography>
                      </td>
                      <td>
                        <Typography
                          className={classes.tableRowText1}
                          noWrap={true}
                        >
                          {employee.usertype === 'manager'
                            ? 'Gerente'
                            : 'Empleado'}
                        </Typography>
                      </td>
                      <td className={classes.backgrounOddColumn}>
                        <Typography
                          className={classes.tableRowText2}
                          noWrap={true}
                        >
                          {employee.lastLogin
                            ? moment(employee.lastLogin).format(
                              'DD/MM/YYYY h:mm:ss a'
                            )
                            : ''}
                        </Typography>
                      </td>
                      <td>
                        <Typography className={classes.tableRowText1}>
                          {employee.isEmployeeActive ? 'Activo' : 'Inactivo'}
                        </Typography>
                      </td>
                      <td className={classes.backgrounOddColumn}>
                        {
                          <div className={classes.actionButonsContainer}>
                            {employee.phoneNumber && isMerchant && (
                              <img
                                width='23'
                                height='23'
                                src={mdiKey}
                                className={classes.actionButon1}
                                onClick={() =>
                                  handleOpenResetPassword(employee)
                                }
                              />
                            )}
                            {currentManager !== employee.userRecordKey && (
                              <img
                                width='23'
                                height='23'
                                src={mdiEdit}
                                className={classes.actionButon2}
                                onClick={() => handleOpenEdit(employee)}
                              />
                            )}
                          </div>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className={classes.empty}>
              <img src={noEmployees} alt='noEmployees' />
              <Typography>No hay empleados.</Typography>
              <PrimaryButton type='button' onClick={handleOpen}>Nuevo empleado</PrimaryButton>
            </div>
          )}
        </div>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        {body}
      </Modal>
      <Modal
        open={openEdit}
        onClose={handleClose}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        {bodyEdit}
      </Modal>
      <Dialog
        open={openResetPassword}
        onClose={() => handleCloseResetPassword(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'¿Desea restablecer la contraseña de este empleado?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            El empleado recibirá un código en su correo electrónico y deberá
            introducirlo para cambiar su contraseña la próxima vez que inicie
            sesión.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseResetPassword(false)}
            color='default'
          >
            Cancelar
          </Button>
          <Button
            onClick={() => handleCloseResetPassword(true)}
            color='default'
            className='outlinedButton'
            autoFocus
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withSnackbar(Tab4);


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 10px 0px 10px',
  },
  cancelButtonStyle: {
    border: '2px solid #DA7B7B',
    boxSizing: 'border-box',
    borderRadius: 5,
    width: 146,
    height: 38,
    margin: 2
  },
  cancelButtonText: {
    ...theme.typography.mediumText,
    color: '#DA7B7B'
  },
  crearButtonStyle: {
    border: '2px solid #40826D',
    boxSizing: 'border-box',
    borderRadius: 5,
    width: 146,
    height: 38,
    margin: 2
  },
  crearButtonText: {
    ...theme.typography.mediumText,
    color: '#40826D'
  },
  tableContainer: {
    display: 'flex',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: '#F9F9F9',
    borderRadius: 5,
    flexDirection: 'row',
  },
  buttonContainer: {
    display: 'flex',
    marginBottom: 25,
  },
  tableGridContainer: {
    width: 'calc(100vw - 87px)',
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 20,
    marginBottom: 20,
  },
  actionButonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: 40,
    alignItems: 'center',
    marginTop: 9,
  },
  actionButon1: {
    cursor: 'pointer',
    marginRight: 10,
  },
  actionButon2: {
    cursor: 'pointer',
    marginLeft: 0,
  },
  tableHeaderText: {
    fontFamily: 'Montserrat Variable',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    color: '#434343',
    borderTop: '1px solid #E0E0E0',
    borderBottom: '1px solid #E0E0E0',
    height: 60,
    justifyContent: 'center',
  },
  tableRowText1: {
    fontFamily: 'Montserrat Variable',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 12,
    paddingTop: 30,
    paddingBottom: 30,
    display: 'flex',
    alignItems: 'center',
    color: '#434343',
    backgroundColor: '#FFFFFF',
    height: 40,
    textAlign: 'center',
    justifyContent: 'center',
  },
  textField: {
    ...theme.typography.smallText,
    fontWeight: '6010',
    textAlign: 'left',
    paddingLeft: 8
  },
  tableRowText2: {
    fontFamily: 'Montserrat Variable',
    fontStyle: 'normal',
    fontWeight: '600l',
    fontSize: 12,
    paddingTop: 30,
    paddingBottom: 30,
    display: 'flex',
    alignItems: 'center',
    color: '#434343',
    height: 40,
    textAlign: 'center',
    justifyContent: 'center',
    backgroundColor: '#F9F9F9',
  },
  buttonStyle: {
    border: '1px solid #3B9A83',
    boxSizing: 'border-box',
    borderRadius: 5,
    width: '100%',
    height: 38,
    margin: 0,
    padding: '0px 20px',
  },
  buttonText: {
    fontFamily: 'Montserrat Variable',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 10,
    color: '#3B9A83',
  },
  plusIcon: {
    marginRight: 10,
  },
  paper: {
    position: 'absolute',
    width: 497,
    height: 'auto',
    backgroundColor: '#F9F9F9',
    borderRadius: 0,
    textAlign: 'center',
    borderRadius: '10px'
  },
  modalBackground: {
    zIndex: '-1',
    backgroundColor: 'rgb(0,0,0,0.1)',
  },
  mainContainerRegister: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: '10px',
    zIndex: '1',
  },
  modalButtons: {
    marginBottom: 20,
    marginTop: 20,
  },
  title: {
    fontFamily: 'Montserrat Variable',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 16,
    color: '#4F4F4F',
    marginTop: 20,
  },
  backgroundOddColumn: {
    backgroundColor: '#F9F9F9',
  },
  redText: {
    color: 'red',
    textAlign: 'right',
    fontSize: 16,
  },
  buttonStyle: {
    border: 0,
    margin: 15,
    backgroundColor: 'white',
  },
  empty: {
    paddingTop: '50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  center: {
    ...theme.globals.center,
  },
}));
