import { API } from 'aws-amplify';
import { API_INVOICE } from '../constants';

const getOrdersByDate = async(from, to,store) => {
    try{
        const result = await API.get(API_INVOICE, '/invoices', {
            queryStringParameters: {
                userid: store,
                status: 'all',
                isNeritoBusiness: true,
                startDate: from,
                endDate: to,
            }
        })
        return {
            success: result.success,
            data: result.data
        }
    }
    catch(error) {
        throw new Error(error);
    }
}

export default getOrdersByDate