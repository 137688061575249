import React, { useState,useEffect } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography, Grid, } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment-timezone';
import { CSVLink, CSVDownload } from "react-csv";

import '../../../assets/css/main.css';
import noMovements from '../../../assets/images/no_movements.svg'
import PrimaryButton from '../../common/primaryButton';
import PrimaryInput from '../../common/primaryInput';
import getTransactionsByDate from '../../../lib/transactions/getTransactionByDate';
import getOrdersByDate from '../../../lib/invoices/getOrdersByDate';
import getStoreById from '../../../lib/store/getStoreById';

const initialFromDate = `${moment().format('YYYY-MM-DD')}T00:00`
const initialToDate = `${moment().format('YYYY-MM-DD')}T23:59`
const perPage = 10

const Tab1 = (props) => {
  useEffect(() => {
    callApi(initialFromDate, initialToDate)
  }, [])

  const classes = useStyles(props);

  const [isLoading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);

  const [totalPaid, setPaid] = useState(0);
  const [totalWithdraw, setWithdraw] = useState(0);
  const [totalDeposit, setDeposit] = useState(0);
  const [totalOnline, setOnline] = useState(0);
  const [totalCommissionsPaid, setCommissionsPaid] = useState(0);
  const [totalCommissionsCharged, setCommissionsCharged] = useState(0);

  const [store, setStore] = useState({});
  const [fromDate, setFromDate] = useState(initialFromDate);
  const [toDate, setToDate] = useState(initialToDate);
  const [selectedPage, setSelectedPage] = useState(1);
  const [pages, setPages] = useState(0);

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const calcTotals = (data) => {
    const specificTotal = type => data
      .filter(mov => mov.type === type)
      .reduce((acc, mov) => acc + (mov.totalCommerce || 0), 0);
    const commissions = (type) => data
      .reduce((acc, mov) => acc + (mov[`commission${type}Commerce`] || 0) + (mov[`iva${type}Commerce`] || 0), 0)
    const paid = specificTotal('inshop_pay');
    const withdraw = specificTotal('inshop_withdraw');
    const deposit = - specificTotal('inshop_deposit');
    const invoices = specificTotal('invoice');
    const refunds = specificTotal('refund');
    const commissionsPaid = commissions('Paid')
    const commissionsCharged = commissions('Charged')
    setPaid(paid.toFixed(2))
    setWithdraw(withdraw.toFixed(2))
    setDeposit(deposit.toFixed(2))
    setOnline((invoices - refunds).toFixed(2))
    setCommissionsPaid(commissionsPaid.toFixed(2))
    setCommissionsCharged(commissionsCharged.toFixed(2))
  }

  const callApi = async (from, to) => {
    if (props.currentStore && props.currentStore.merchant) {
      const timeStampFrom = moment.utc(from).valueOf()
      const timeStampTo = moment.utc(to).valueOf()

      if (timeStampFrom < timeStampTo) {
        setLoading(true)
        try {
          const storeResponse = await getStoreById(props.currentStore.id,props.currentStore.merchant)
          setStore(storeResponse.data)

          const getOrders = await getOrdersByDate(timeStampFrom, timeStampTo,props.currentStore.id)
          const getTrans = await getTransactionsByDate(props.currentStore.id,timeStampFrom, timeStampTo)

          if(getOrders.success || getTrans.success){
            const allTransactions = getTrans.data.length > 0 ? getTrans.data.map((it) => {
              return {
                ...it, 
                employee: it.createdByRole === 'user' ?
                it.readByRole === "merchant" ? "" : it.readByName
                : it.createdByName,
                client: it.createdByRole === 'user' ? it.createdByName 
                : it.readByRole === 'merchant' ? "" : it.readByName
              }
            })
            : []

            const allOrders = getOrders.data.length > 0 ? getOrders.data.map((it) => {
              return {
                ...it,
                client: it.lendFullName
              }
            })
            : []

            const allMoves = [...allOrders,...allTransactions].flat()
            setPages(Math.ceil(allMoves.length / perPage))
            setTransactions(allMoves)
            calcTotals(allMoves)
            setLoading(false)
          }
          else {
            handleAlert('error', getOrders || getTrans)
            setLoading(false)
          }

        } catch (err) {
          handleAlert('error', err.message)
          setLoading(false)
        }
      } else {
        handleAlert('error', 'La fecha inicio debe ser menor a la fecha de fin')
      }
    }
  };

  const handleChange = (event) => {
    const { value, name } = event.target
    switch (name) {
      case 'fromDate':
        setFromDate(value)
        callApi(value, toDate)
        break;
      case 'toDate':
        setToDate(value)
        callApi(fromDate, value)
        break;
      default:
        handleAlert('error', `Input no válido ${name}`)
    }
  }

  const handlePagination = (event, value) => {
    setSelectedPage(value)
  }

  const mapType = (type) => {
    switch (type) {
      case 'inshop_withdraw':
        return 'RETIRO'
      break;
      case 'withdraw':
        return 'RETIRO'
      break;
      case 'inshop_pay':
        return 'VENTA EN CAJA'
        break;
      case 'payment':
        return 'VENTA EN CAJA'
      break;
      case 'inshop_deposit':
        return 'DEPÓSITO'
        break;
      case 'deposit':
        return 'DEPÓSITO'
      break;
      case 'invoice':
        return 'ORDEN'
        break;
      case 'refund':
        return 'REEMBOLSO'
        break;
      default:
        return 'ORDEN'
    }
  }

  const downloadCsv = () => {
    const csvData = [["Tipo", "Fecha", "Hora", "Cliente", "Empleado", "Monto",
      "Cobrado Comercio", "IVA", "Pagado Comercio", "IVA", "Total", "Balance"]]
    transactions.forEach((transaction) => (
      csvData.push([
        mapType(transaction.type),
        moment(transaction.createdAt).format('DD/MM/YYYY'),
        moment(transaction.createdAt).format('h:mm:ss a'),
        '*' + transaction.client.substr(transaction.client.length - 4, transaction.client.length - 1),
        transaction.employee,
        transaction.amount.toFixed(2),
        (transaction.commissionChargedCommerce || 0).toFixed(2),
        (transaction.ivaChargedCommerce || 0).toFixed(2),
        (transaction.commissionPaidCommerce || 0).toFixed(2),
        (transaction.ivaPaidCommerce || 0).toFixed(2),
        ((transaction.type === 'inshop_deposit' || transaction.type === 'refund') ? '-' : '') + (transaction.totalCommerce ? transaction.totalCommerce.toFixed(2) : transaction.amount.toFixed(2)),
        (transaction.newStoreBalance || 0).toFixed(2),
      ])
    ))
    ReactDOM.unmountComponentAtNode(document.querySelector('#csv'));
    const element = (<CSVDownload data={csvData} target="_blank" />);
    ReactDOM.render(element, document.querySelector('#csv'));
  }

  const setMoneyFormat = (number) => {
    if (number != 'N/A') {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return number
    }
  }

  const getStatus = (status = null) => {
    if(status === null){
      return { label: '', color: 'transparent' }
    }
    else if(status.includes('precomplete')){
      return { label: 'En proceso', color: '#23A1C1' }
    }
    else if(status.includes('processing')){
      return { label: 'En preparación', color: '#F2C94C' }
    }
    else if(status.includes('ready')){
      return { label: 'Listo', color: '#6EB89D' }
    }
    else if(status.includes('delivered')){
      return { label: 'Entregado', color: '#23A1C1' }
    }
    else if(status.includes('complete')){
      return { label: 'Completado', color: '#6EB89D' }
    }
    else if(status.includes('waiting') || status.includes('received')){
      return { label: 'En espera', color: '#EDDD64' }
    }
    else if(status.includes('canceled')){
      return { label: 'Cancelado', color: '#EE715B' }
    }
    else {
      return { label: '', color: 'transparent' }
    }
  }

  return (
    <div className={classes.root}>
      {isLoading ?
        <div className='gralinfoform align-center justify-center text-center'>
          <CircularProgress color="secondary" />
        </div>
        :
        <div className={classes.container}>
          <div className="row col-12">
            <div className={classes.filterConteiner}>
              <Grid container>
                <Grid item xs={12} sm={12} md={5} className={classes.row}>
                  <Typography className={classes.totalsLabel}>
                    Desde
                  </Typography>
                  <PrimaryInput
                    onChange={handleChange}
                    value={fromDate}
                    id={'fromDate'}
                    type="datetime-local"
                    name={'fromDate'}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={5} className={classes.row}>
                  <Typography className={classes.totalsLabel}>
                    Hasta
                  </Typography>
                  <PrimaryInput
                    onChange={handleChange}
                    value={toDate}
                    id={'toDate'}
                    type="datetime-local"
                    name={'toDate'}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <PrimaryButton
                    onClick={() => downloadCsv()}
                    children='Descargar Movimientos'
                    fullWidth
                  />
                </Grid>
              </Grid>
            </div>
            <div className={' col-sm-12 col-lg-12 row'}>
              <div className={'lbl-total ' + classes.totalsContainer}>
                <Typography className={classes.totalsLabel}>Pagos($)</Typography>
                <Typography className={classes.totalsValueAlt}>{setMoneyFormat(totalPaid)}</Typography>
              </div>
              <div className={'lbl-total ' + classes.totalsContainer}>
                <Typography className={classes.totalsLabel}>Retiros($)</Typography>
                <Typography className={classes.totalsValueAlt}>{setMoneyFormat(totalWithdraw)}</Typography>
              </div>
              <div className={'lbl-total ' + classes.totalsContainer}>
                <Typography className={classes.totalsLabel}>Depósitos($)</Typography>
                <Typography className={classes.totalsValueAlt}>{setMoneyFormat(totalDeposit)}</Typography>
              </div>
              <div className={'lbl-total ' + classes.totalsContainer}>
                <Typography className={classes.totalsLabel}>En Línea($)</Typography>
                <Typography className={classes.totalsValueAlt}>{setMoneyFormat(totalOnline)}</Typography>
              </div>
              <div className={'lbl-total ' + classes.totalsContainer}>
                <Typography className={`${classes.totalsLabel}`}>Comisiones Generadas($)</Typography>
                <Typography className={`${classes.totalsValue}`}>{setMoneyFormat(totalCommissionsCharged)}</Typography>
              </div>
              <div className={'lbl-total ' + classes.totalsContainer}>
                <Typography className={classes.totalsLabel}>Comisiones Pagadas($)</Typography>
                <Typography className={classes.totalsValue}>{setMoneyFormat(totalCommissionsPaid)}</Typography>
              </div>
              <div className={'lbl-total ' + classes.totalsContainer}>
                <Typography className={classes.totalsLabel}>Balance Total($)</Typography>
                <Typography className={classes.totalsValueAlt}>{setMoneyFormat((store.balance || 0).toFixed(2))}</Typography>
              </div>
            </div>
          </div>

          {transactions.length > 0 ? 
            <div className={`mt-3 mt-5 w-100 ${classes.table}`}>
              <div className="overflow-auto table-responsive">
                <table className="table mt-4">
                  <thead>
                    <tr>
                      <th>
                        <Typography className={classes.tableHeaderText}>Tipo de movimiento</Typography>
                      </th>
                      <th>
                        <Typography className={classes.tableHeaderText}>Estado de movimiento</Typography>
                      </th>
                      <th>
                        <Typography className={classes.tableHeaderText}>Fecha</Typography>
                      </th>
                      <th>
                        <Typography className={classes.tableHeaderText}>Hora</Typography>
                      </th>
                      <th>
                        <Typography className={classes.tableHeaderText}>Cliente</Typography>
                      </th>
                      <th>
                        <Typography className={classes.tableHeaderText}>Empleado</Typography>
                      </th>
                      <th>
                        <Typography className={classes.tableHeaderText}>Monto</Typography>
                      </th>
                      <th>
                        <Typography className={classes.tableHeaderText}>Cobrado Comercio</Typography>
                      </th>
                      <th>
                        <Typography className={classes.tableHeaderText}>IVA</Typography>
                      </th>
                      <th>
                        <Typography className={classes.tableHeaderText}>Pagado Comercio</Typography>
                      </th>
                      <th>
                        <Typography className={classes.tableHeaderText}>IVA</Typography>
                      </th>
                      <th>
                        <Typography className={classes.tableHeaderText}>Total</Typography>
                      </th>
                      <th>
                        <Typography className={classes.tableHeaderText}>Balance</Typography>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions.slice(((selectedPage - 1) * perPage), (((selectedPage - 1) * perPage) + perPage)).map((transaction) => (
                      <tr key={transaction.id}>
                        <td className={classes.backgrounOddColumn}>
                          <Typography className={classes.tableRowText}>{mapType(transaction.type)}</Typography>
                        </td>
                        <td style={{display: 'flex', alignItems: 'center',justifyContent: 'center'}}>
                          <Typography 
                            className={`${classes.tableRowText} ${classes.status}`
                            }
                            style={{backgroundColor: getStatus(transaction.orderStatus ||transaction.status).color }}
                          >{getStatus(transaction.orderStatus ||transaction.status).label}</Typography>
                        </td>
                        <td className={classes.backgrounOddColumn}>
                          <Typography className={classes.tableRowText}>{moment(transaction.createdAt).format('DD/MM/YYYY')}</Typography>
                        </td>
                        <td>
                          <Typography className={classes.tableRowText}>{moment(transaction.createdAt).format('h:mm:ss a')}</Typography>
                        </td>
                        <td className={classes.backgrounOddColumn}>
                          {/* <Typography className={classes.tableRowText}>*{transaction.client.substr(transaction.client.length - 4, transaction.client.length - 1)}</Typography> */}
                          <Typography className={classes.tableRowText}>{transaction.client}</Typography>
                        </td>
                        <td>
                          <Typography className={classes.tableRowText}>{transaction.employee || ''}</Typography>
                        </td>
                        <td className={classes.backgrounOddColumn}>
                          <Typography className={classes.tableRowNumber}>{setMoneyFormat(transaction.amount.toFixed(2))}</Typography>
                        </td>
                        <td>
                          <Typography className={classes.tableRowNumber}>{setMoneyFormat((transaction.commissionChargedCommerce || 0).toFixed(2))}</Typography>
                        </td>
                        <td className={classes.backgrounOddColumn}>
                          <Typography className={classes.tableRowNumber}>{setMoneyFormat((transaction.ivaChargedCommerce || 0).toFixed(2))}</Typography>
                        </td>
                        <td>
                          <Typography className={classes.tableRowNumber}>{setMoneyFormat((transaction.commissionPaidCommerce || 0).toFixed(2))}</Typography>
                        </td>
                        <td className={classes.backgrounOddColumn}>
                          <Typography className={classes.tableRowNumber}>{setMoneyFormat((transaction.ivaPaidCommerce || 0).toFixed(2))}</Typography>
                        </td>
                        <td>
                          <Typography className={classes.tableRowNumber}>{(transaction.type === 'inshop_deposit' || transaction.type === 'refund') && '-'}{setMoneyFormat(transaction.totalCommerce ? transaction.totalCommerce.toFixed(2) : transaction.amount.toFixed(2))}</Typography>
                        </td>
                        <td className={classes.backgrounOddColumn}>
                          <Typography className={classes.tableRowNumber}>{setMoneyFormat((transaction.newStoreBalance || 0).toFixed(2))}</Typography>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className={classes.paginationTAB}>
                <Pagination count={pages} shape="rounded" onChange={handlePagination} />
              </div>
            </div>
            :
            <div className={classes.errorContainer}>
              <img src={noMovements} />
              <Typography className={classes.emptyMovements} >No se encontraron movimientos</Typography>
              <Typography className={classes.advice} >Por favor selecciona otro rango de fechas</Typography>
            </div>
          }
        </div>
      }
      { <div id="csv" />}
    </div >
  );
}

export default withSnackbar(Tab1)

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 10px 20px 0px',
    justifyContent: 'center',
    alignContent:'center',
    alignItems:'center'
  },
  tableHeaderText: {
    ...theme.typography.smallText,
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #BDBDBD',
    borderBottom: '1px solid #BDBDBD',
    height: 60,
    justifyContent: 'center'
  },
  tableRowText: {
    ...theme.typography.smallText,
    fontWeight: '600',
    paddingTop: 15,
    paddingRight: 15,
    paddingLeft: 15,
    paddingBottom: 15,
    display: 'flex',
    alignItems: 'center',
    height: 10,
    textAlign: 'center',
    justifyContent: 'center'
  },
  tableRowNumber: {
    ...theme.typography.smallText,
    fontWeight: '600',
    paddingTop: 15,
    paddingRight: 15,
    paddingLeft: 15,
    paddingBottom: 15,
    display: 'flex',
    alignItems: 'center',
    height: 10,
    textAlign: 'center',
    justifyContent: 'flex-end',
  },
  buttonStyle: {
    border: '1px solid #3B9A83',
    boxSizing: 'border-box',
    borderRadius: 5,
    width: 146,
    height: 38,
    margin: 2
  },
  title: {
    ...theme.typography.title,
    marginTop: 20
  },
  totalsContainer: {
    // border: '1px solid #BDBDBD',
    // borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '130px',
    width: '100%',
    height: 90,
    justifyContent: 'center',
    margin: '0px auto 5px'
  },
  totalsLabel: {
    ...theme.typography.paragraph,
    width: '100%',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
  totalsValue: {
    ...theme.typography.totalsValue,
    marginTop: 5,
    textAlign: 'left'
  },
  totalsValueAlt: {
    ...theme.typography.totalsValue,
    textAlign: 'center',
    paddingTop: 28,
  },
  downloadCsvButtonStyle: {
    border: '1px solid #3B9A83',
    borderRadius: 5,
    height: '100%',
    textAlign: 'center',
    justifyContent: 'center',
  },
  backgrounOddColumn: {
    backgroundColor: '#F6F6F6',
    padding: 3
  },
  filterConteiner: {
    border: '1px solid #BDBDBD',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingRight: 40,
    alignItems: 'center',
    marginBottom: 20,
    textAlign: 'center',
    justifyContent: 'center',
    alignContent:'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      paddingRight: 20,
      paddingLeft: 20,
    },
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 50,
    marginbottom: 80
  },
  emptyMovements: {
    ...theme.typography.paragraph,
    marginTop: 20
  },
  advice: {
    ...theme.typography.advice,
    opacity: '0.6'
  },
  paginationTAB: {
    widht: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent:'center',
    alignItems:'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  table: {
    padding: '0px 15px 0px 15px'
  },
  status: {
    ...theme.typography.smallText,
    color: 'white',
    fontWeight: '900',
    textAlign: 'center',
    padding: '10px !important',
    width: '100%',
    marginLeft: '5%',
    marginRight: '5%',
    borderRadius: 3
  }
}));