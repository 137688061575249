import { API } from 'aws-amplify';
import { API_STORE } from '../constants';

/**
 * create an account in Nerito Pay
 * @param {string} storeRecordKey
 * @returns {object} with success, message, errorType, result
 */
const updateStore = async (storeRecordKey) => {
  try {
    const result = await API.put(API_STORE, `/store/deactivateStore`,
      {
        body: { storeRecordKey: storeRecordKey }
      })
    return {
      success: result.success
    }
  }
  catch (err) {
    return {
      success: false,
      errorType: 'api',
      message: err,
    }
  }
}

export default updateStore;