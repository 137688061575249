import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentUser } from '../../redux/slices/userSlice';
import { useHistory } from 'react-router-dom';
import { Auth, API, Storage } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  CircularProgress,
  Paper,
  Tooltip
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import '../../assets/css/main.css';
import modalMessagePicture from '../../assets/images/modal_message_picture.svg';
import checkCircle from '../../assets/images/check-circle.png';
import approvedCircle from '../../assets/images/approved_circle.svg';
import getMerchantData from '../../lib/getMerchant';
import checkIncompleteLegalDocs from '../../lib/checkIncompleteLegalDocs';
import { API_STORE } from '../../lib/constants';
import defaultStoreIcon from './../../assets/images/default_store_icon.svg';
import PrimarySearchBar from '../common/primarySearchBar';
import PrimaryButton from '../common/primaryButton';
import noStoreImage from '../../assets/images/default_store_icon_1.svg';
import DefaultModal from '../modal';
import PrimaryFooter from '../common/primaryFooter'
import removeAccents from '../../lib/removeAccents';

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  };
}

function Dashboard(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    callApi();
  }, []);

  let history = useHistory();

  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [isPendding, setPendding] = useState(false);
  const [isApproved, setApproved] = useState(false);
  const [isRejected, setRejected] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [stores, setStores] = useState([]);
  const [shownStores, setShownStores] = useState([]);
  const [snapshots, setSnapshots] = useState([]);
  const [storeprofilepics, setStoreProfilePics] = useState([]);
  const [merchantPicture, setProfilePicture] = useState();
  const [currentMerchant, setMerchant] = useState(null);
  const [merchantId, setMerchantID] = useState(null);
  const [modalAddStore, setAddStore] = useState(false);
  const [reminder, setReminder] = useState(true);

  const classes = useStyles();

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const callApi = async () => {
    try {
      setLoading(true);
      const getUser = await Auth.currentAuthenticatedUser();
      const userKey = getUser.attributes['sub'];
      dispatch(setCurrentUser(userKey));
      if (getUser.attributes === undefined) {
        handleAlert('error', 'Ah ocurrido un error al cargar los datos del usuario');
        history.push('/login');
      } else {
        if (
          getUser.signInUserSession.idToken.payload['cognito:groups'][0] ===
          'admin'
        ) {
          history.push('/admin/dashboard');
        } else if (
          getUser.signInUserSession.idToken.payload['cognito:groups'][0] ===
          'employee' ||
          getUser.signInUserSession.idToken.payload['cognito:groups'][0] ===
          'user'
        ) {
          handleAlert('info', 'Usuario restringido');
          history.push('/login');
        } else {
          try {
            const getGralProfile = await API.get(
              'profile-api',
              `/profile/${userKey}`,
            );
            let merchant = null;
            let isMerchant = false;
            if (getGralProfile.data[0].merchantRecordKey) {
              isMerchant = true;
              merchant = await getMerchantData(
                'merchant',
                getGralProfile.data[0].merchantRecordKey
              );
            } else if (
              getGralProfile.data[0].isEmployeeOfStore &&
              getGralProfile.data[0].isEmployeeActive
            ) {
              merchant = await getMerchantData(
                'store',
                getGralProfile.data[0].isEmployeeOfStore
              );
            } else if (
              getGralProfile.data[0].isManagerOfStore &&
              getGralProfile.data[0].isEmployeeActive
            ) {
              merchant = await getMerchantData(
                'store',
                getGralProfile.data[0].isManagerOfStore
              );
            }
            if (merchant === null) {
              handleAlert(
                'error',
                'Ocurrió un error al obtener la información del usuario'
              );
              history.push('/login');
            } else {
              if (
                getGralProfile.data[0].hasAvatar ||
                getGralProfile.data[0].isManagerOfStore
              ) {
                const pImage = await Storage.get(
                  `profiles/${merchant.merchantRecordKey}/default.jpg`
                );
                setProfilePicture(pImage);
              }
              setMerchant(merchant);
              setMerchantID(merchant.merchantRecordKey);
              handleOpen(merchant, isMerchant);
              const getStores = await API.get(API_STORE, '/store', {
                queryStringParameters: {
                  merchant: merchant.merchantRecordKey,
                  isFor: 'merchant',
                },
              });
              if (getStores.data && getStores.data.Items) {
                const imagesP = getStores.data.Items.map((store) =>
                  Storage.get(`store/${store.id}/default.jpg`)
                );
                const pImages = await Promise.all(imagesP);
                const storesWithImages = getStores.data.Items.map(
                  (store, index) => {
                    return {
                      ...store,
                      storeImage: pImages[index],
                    };
                  }
                );
                const sortedStores = storesWithImages.sort((a, b) => b.published - a.published);
                setStores(sortedStores);
                setShownStores(sortedStores);
                setStoreProfilePics(pImages);
                const snapshotsP = getStores.data.Items.map((store) =>
                  Storage.get(`store/${store.id}/loc.jpg`)
                );
                const links = await Promise.all(snapshotsP);
                setSnapshots(links);
              }
              setLoading(false);
            }
          } catch (err) {
            handleAlert('error', err);
          }
        }
      }
    } catch (e) {
      history.push('/login');
    }
  };

  const handleOpen = async (merchant, isMerchant) => {
    if (isMerchant) {
      if (merchant.merchantStatus === 'rejected') {
        setRejected(true);
        setOpen(true);
      } else if (merchant.merchantStatus === 'progress') {
        const incomplete = checkIncompleteLegalDocs(merchant, 'merchant');
        incomplete.length > 0 ? setRejected(true) : setPendding(true);
        setOpen(true);
      } else if (merchant.merchantStatus === 'approved') {
        if (merchant.showApproved) {
          setApproved(true);
          setOpen(true);
          try {
            await API.post('merchant-api', '/merchant', {
              body: {
                merchantRecordKey: merchant.merchantRecordKey,
                showApproved: false,
              },
            });
          } catch (err) {
          }
        }
      } else {
        setOpen(true);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const goToStores = () => {
    setOpen(false);
    history.push({
      pathname: '/stores',
    });
  };

  const goToProducts = () => {
    setOpen(false);
    history.push({
      pathname: '/products',
    });
  };

  const goToProfile = () => {
    setOpen(false);
    history.push({
      pathname: '/profile',
    });
  };

  const goToDetails = (storeId) => {
    history.push({ pathname: '/storeDetails', state: { storeId, merchantId, merchantPicture, } });
  };

  const handleSearchChange = (e) => {
    const search = removeAccents(e.target.value).toLowerCase();
    const filteredStores = stores.filter((store) =>
      removeAccents(store.storeName).toLowerCase().includes(search)
    );
    setShownStores(filteredStores);
  };

  const body = (
    <div className={classes.centerVertical}>
      <img
        src={modalMessagePicture}
        width={75}
        height={75}
        style={{ paddingTop: 0 }}
        alt='warning'
      />
      <div className={classes.spacer1} />
      <Typography className={classes.paragraph}>
        Recuerda completar tu perfil
      </Typography>
      <Typography className={classes.paragraph}>
        para poder utilizar Nerito
      </Typography>
      <div className={classes.spacer1} />
      <PrimaryButton onClick={goToProfile} fullWidth>
        Completar mi perfil
      </PrimaryButton>
    </div>
  );

  const bodyRejected = (
    <div className={classes.centerVertical}>
      <img
        src={modalMessagePicture}
        width={75}
        height={75}
        style={{ paddingTop: 0 }}
        alt='warning'
      />
      <div className={classes.spacer1} />
      <Typography className={classes.paragraph}>
        El administrador ha revisado tu perfil y se requiere tu intervención.
        Por favor, revisa
      </Typography>
      <Typography className={classes.paragraph}>
        Notas del Administrador.
      </Typography>
      <div className={classes.spacer1} />
      <PrimaryButton fullWidth onClick={goToProfile}>
        Revisar mi perfil
      </PrimaryButton>
    </div>
  );

  const bodyApproved = (
    <div className={classes.centerVertical}>
      <img
        src={approvedCircle}
        width={75}
        height={75}
        style={{ paddingTop: 10 }}
        alt='approved'
      />
      <div className={classes.spacer1} />
      <Typography className={classes.paragraph}>
        ¡Felicidades! tu perfil ha sido aprobado y ya puedes empezar a usar
        todos los beneficios.
      </Typography>
      <Typography className={classes.paragraph}>
        Empieza creando una sucursal para tu empresa.
      </Typography>
      <div className={classes.spacer1} />
      <PrimaryButton fullWidth onClick={goToStores}>
        Crear Sucursal
      </PrimaryButton>
    </div>
  );

  const bodyPending = (
    <div className={classes.centerVertical}>
      <img
        src={checkCircle}
        width={75}
        height={75}
        style={{ paddingTop: 0 }}
        alt='warning'
      />
      <div className={classes.spacer1} />
      <Typography className={classes.paragraph}>
        ¡Gracias por completar tu perfil empresarial!
      </Typography>
      <div className={classes.spacer1} />
      <Typography className={classes.paragraph}>
        Estamos procesando tu información y al terminar
      </Typography>
      <Typography className={classes.paragraph}>
        recibirás un correo notificando el resultado.
      </Typography>
      <div className={classes.spacer1} />
      <Typography className={classes.paragraph}>
        Mientras tanto, te sugerimos empezar a registrar
      </Typography>
      <Typography className={classes.paragraph}>
        tus sucursales y empleados o bien,
      </Typography>
      <Typography className={classes.paragraph}>
        subir tu catálogo de productos.
      </Typography>
      <div className={classes.spacer1} />
      <div className={classes.centerVerticalButtons}>
        <PrimaryButton fullWidth onClick={goToStores}>
          Crear Sucursal
        </PrimaryButton>
        <div className={classes.spacer1} />
        <PrimaryButton fullWidth onClick={goToProducts}>
          Crear Productos
        </PrimaryButton>
      </div>
    </div>
  );

  const storeCard = (store, index) => {
    return (
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        lg={3}
        xl={2}
        onClick={() => goToDetails(store.id)}
      >
        <Paper className={store.published ? `${classes.cardContainerActive} ${classes.cardContainer}` : `${classes.cardContainerInactive} ${classes.cardContainer}`}>
          <Grid container>
            <Grid item xs={12} sm={4} className={classes.cardImageContainer}>
              <img
                className={classes.cardImage}
                src={
                  store.hasAvatar
                    ? store.storeImage
                    : merchantPicture
                      ? merchantPicture
                      : defaultStoreIcon
                }
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <div className={classes.titleAndStatus}>
                <Typography className={classes.cardTitleText}>
                  {store.storeName}
                </Typography>
                <Tooltip title={store.published ? 'Tienda activa' : 'Tienda desactivada'}>
                  <div className={store.published ? classes.publishedStatusTextActive : classes.publishedStatusTextNoActive}></div>
                </Tooltip>
              </div>
              <Typography className={classes.cardSubtitle}>
                {`${store.schedules[0].start} - ${store.schedules[0].end}`}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid xs={12}>
              <Typography className={classes.boxTitle}>Mis Pedidos</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div className={classes.boxPending}>
                <div className={classes.boxText}>Pendientes</div>
                <div className={classes.boxNumber}>
                  {store.receivedOrders || 0}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div className={classes.boxProcessing}>
                <div className={classes.boxText}>Preparándose</div>
                <div className={classes.boxNumber}>
                  {store.processingOrders || 0}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div className={classes.boxReady}>
                <div className={classes.boxText}>Listos</div>
                <div className={classes.boxNumber}>
                  {store.readyOrders || 0}
                </div>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

    );
  };

  if (isLoading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress color='secondary' />
      </div>
    );
  }

  const handleCloseAddStore = () => {
    setAddStore(false);
    callApi();
  };

  return (
    <>
      <div className={classes.container}>
        <Grid container>
          <Grid item xs={12} className={classes.titleContainer}>
            <Typography className={classes.title}>
              Bienvenido a{' '}
              <span className={classes.greenTitle}>Nerito para empresas</span>,
              estas son tus Sucursales.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <PrimarySearchBar
              type='search'
              placeholder='Buscar'
              autoFocus
              onChange={(e) => handleSearchChange(e)}
            />
          </Grid>
        </Grid>

        {shownStores.length > 0 ? (
          <div className={`${classes.content} scrollBarHidden`}>
            <Grid container spacing={2}>
              {shownStores.map((store, index) => storeCard(store, index))}
            </Grid>
          </div>
        ) : (
          <div className={classes.empty}>
            <img src={noStoreImage} alt='noStoreImage' />
            <Typography>No hay sucursales.</Typography>
            <PrimaryButton type='button' onClick={() => setAddStore(true)}>
              Agregar una sucursal
            </PrimaryButton>
          </div>
        )}

        <DefaultModal
          open={open}
          handleClose={handleClose}
          modalType='welcome'
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
          content={
            isRejected
              ? bodyRejected
              : isPendding
                ? bodyPending
                : isApproved
                  ? bodyApproved
                  : body
          }
        ></DefaultModal>

        <DefaultModal
          open={modalAddStore}
          handleClose={handleCloseAddStore}
          modalType='newStore'
        ></DefaultModal>
      </div>
      {reminder ? (
        <div className={classes.footer}>
          <PrimaryFooter
            onClickClose={() => setReminder(false)}
          />
        </div>
      ) : null}
    </>
  );
}

export default withSnackbar(Dashboard);

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 0,
    overflow: 'hidden',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.lightGrey.main}`,
    marginBottom: 15,
  },
  title: {
    ...theme.typography.title,
    paddingTop: 10,
    paddingBottom: 10,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  greenTitle: {
    color: theme.palette.primary.main,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    overflowX: 'hidden',
    padding: 10,
  },
  cardContainer: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    cursor: 'pointer',
    transition: 'box-shadow 0.3s ease-in-out',
    '&:hover': {
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.75)',
    },
  },
  cardContainerActive: {
    backgroundColor: 'white'
  },
  cardContainerInactive: {
    backgroundColor: '#E7E7E7'
  },
  cardImageContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  cardImage: {
    height: 60,
    width: 60,
    borderRadius: 50,
  },
  cardTitle: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
  cardTitleText: {
    ...theme.typography.cardTitle,
    paddingRight: 10,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  cardSubtitle: {
    ...theme.typography.mediumText,
    fontWeight: 500,
  },
  boxTitle: {
    ...theme.typography.mediumText,
    ...theme.globals.center,
    marginBottom: 5,
  },
  boxText: {
    textAlign: 'center',
    marginLeft: 10,
    marginRight: 10,
  },
  boxNumber: {
    ...theme.typography.title,
    color: theme.palette.white.main,
  },
  boxPending: {
    ...theme.globals.cardWithNumber,
    backgroundColor: '#CEC054',
  },
  boxProcessing: {
    ...theme.globals.cardWithNumber,
    backgroundColor: '#F2B04C',
  },
  boxReady: {
    ...theme.globals.cardWithNumber,
    backgroundColor: '#6EB89D',
  },
  empty: {
    margin: 'auto',
    alignItems: 'center',
    paddingTop: '50px',
    textAlign: 'center',
  },
  loadingContainer: {
    margin: 'auto',
    paddingTop: '50px',
    ...theme.globals.center,
  },
  centerVertical: {
    ...theme.globals.center,
    flexDirection: 'column',
  },
  centerVerticalButtons: {
    ...theme.globals.center,
    flexDirection: 'column',
    width: '100%',
  },
  paragraph: {
    ...theme.typography.paragraph,
    color: theme.palette.gray.main,
  },
  spacer1: { paddingTop: 20 },
  footerContainer: {
    position: 'sticky',
    left: '0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#fafafa',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 20,
    width: '100%',
    padding: 20,
    [theme.breakpoints.only('sm')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  textAndButtonGroupContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  reminderTitle: {
    ...theme.typography.subtitle,
    color: theme.palette.gray.main,
    width: '100%',
  },
  buttonGroupContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  publishedStatus: {
    width: '50%',
    textAlign: 'end',
  },
  publishedStatusTextNoActive: {
    backgroundColor: theme.palette.red.main,
    width: '10px',
    height: '10px',
    borderRadius: '10px',
  },
  publishedStatusTextActive: {
    backgroundColor: theme.palette.primary.main,
    width: '10px',
    height: '10px',
    borderRadius: '10px',
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
  },
  titleAndStatus: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }
}));