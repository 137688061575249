import { API } from 'aws-amplify';
import { API_MERCHANT } from '../constants';

/**
 * create an account in Nerito Pay
 * @param {string} merchantRecordKey
 * @returns {object} with success, message, errorType, result
 */
const createAccount = async (merchantRecordKey,) => {
  try {
    const result = await API.post(API_MERCHANT, `/merchant/createCommissionAgent`,
      {
        body:
          { merchantRecordKey: merchantRecordKey }
      })
    return {
      success: true,
      result: result.data,
    }
  }
  catch (err) {
    return {
      success: false,
      errorType: 'api',
      message: err.message,
    }
  }
}

export default createAccount;