import { API } from 'aws-amplify';
import { API_TRANSACTION } from '../../lib/constants';

const getTransactionsByDate = async (merchantRecordKey,startDate = null,endDate = null,type = null) => {
    try{
        const result = await API.get(API_TRANSACTION, '/transactions', {
            queryStringParameters: {
              userRecordKey: merchantRecordKey,
              startDate: startDate,
              endDate: endDate,
              type: type
            },
        });
        return {
            success: result.success,
            data: result.data
        }
    }
    catch(error) {
        throw new Error(error);
    }
}

export default getTransactionsByDate