import { API } from 'aws-amplify';
import { API_STORE } from '../constants';

const getStoreById = async(storeRecordKey, merchantRecordKey) => {
    try{
        const result = await API.get(API_STORE, `/store/${storeRecordKey}?merchant=${merchantRecordKey}`)
        return {
            success: result.success,
            data: result.data.Items[0]
        }
    }
    catch(error) {
        throw new Error(error);
    }
}

export default getStoreById