// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
// PROD
const awsBusiness = {
  aws_project_region: 'us-west-2',
  aws_cognito_identity_pool_id: 'us-west-2:7a2b5b48-0795-4c9e-b4be-85eff8cd93ed',
  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: 'us-west-2_CBxYpoTyG',
  aws_user_pools_web_client_id: '355789t0v4t25cqspk7pfp1rpb',
  oauth: {
    domain:
      'nerito-wallet-monectaauthbyemail-hosteduidomain-prod.auth.us-west-2.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'myapp://',
    redirectSignOut: 'myapp://',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'profile-api',
      endpoint: 'https://hdpa9xcmt1.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'transaction-api',
      endpoint: 'https://dlrrb2piz0.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    // {
    //   name: 'credit-api',
    //   endpoint: 'https://qwmgxe2x0b.execute-api.us-west-2.amazonaws.com/prod',
    //   region: 'us-west-2',
    // },
    {
      name: 'event-api',
      endpoint: 'https://yd25egzjad.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'inventory-api',
      endpoint: 'https://wjsxg85uq8.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'invitation-api',
      endpoint: 'https://g5l6o0c2ng.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'invoice-api',
      endpoint: 'https://f4nc3i2td2.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'payment-api',
      endpoint: 'https://mr0vlqomjg.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'product-api',
      endpoint: 'https://357yiqjr1c.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'setting-api',
      endpoint: 'https://986dqjn985.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'store-api',
      endpoint: 'https://m4ozph5wuf.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'merchant-api',
      endpoint: 'https://n2mjzwcm7h.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'inshop-api',
      endpoint: 'https://q7vsdkr8qi.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'keys-api',
      endpoint: 'https://ryg3dblowf.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'funds-api',
      endpoint: 'https://uc1e4bpula.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'notifications-push-api',
      endpoint: 'https://jg6q1f4vp2.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    // {
    //   name: 'speis-api',
    //   endpoint: '',
    //   region: 'us-west-2',
    // },
    // {
    //   name: 'integrations-api',
    //   endpoint: '',
    //   region: 'us-west-2',
    // },
    {
      name: 'locations-api',
      endpoint: 'https://89x6qfch2k.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    // {
    //   name: 'besign-api',
    //   endpoint: '',
    //   region: 'us-west-2',
    // },
    // {
    //   name: 'emidamex-api',
    //   endpoint: '',
    //   region: 'us-west-2',
    // },
    // {
    //   name: 'siti-api',
    //   endpoint: '',
    //   region: 'us-west-2',
    // },
    // {
    //   name: 'zohoDesk-api',
    //   endpoint: '',
    //   region: 'us-west-2',
    // },
    {
      name: 'customer-api',
      endpoint: 'https://71jqxg7jsa.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
  ],
  aws_appsync_graphqlEndpoint: 'https://vtlv3bzpqvdmbfxd5ye3lxgkvy.appsync-api.us-west-2.amazonaws.com/graphql',
  aws_webSocket_Endpoint: 'wss://hqg5fvlhj7.execute-api.us-west-2.amazonaws.com/prod',
  aws_appsync_region: 'us-west-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_user_files_s3_bucket: 'nerito-wallet-bucket-prod-us-west-2',
  aws_user_files_s3_bucket_region: 'us-west-2',
};

export default awsBusiness;
