import React, { useEffect } from 'react'
import { withGoogleMap, GoogleMap, withScriptjs, Marker } from "react-google-maps";
import Geocode from 'react-geocode';
import {Storage as s3} from 'aws-amplify';
import awsBusiness from '../../../aws-exports';

const awsmobile = awsBusiness;

const onDragEnd = async (event) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();
    try {
        var response = await Geocode.fromLatLng(newLat, newLng);
        console.info(response);
        return response.results[0];
    }
    catch (err) {
        console.error(err);
    }
    return undefined;
}

const getLocData = (placeArr) => {
    const arr = placeArr.address_components;
    const getField = (field) => {
        for (let i = 0; i < arr.length; i++) {
            for (let j = 0; j < arr[i].types.length; j++) {
                if (arr[i].types[j] &&
                    (
                        field === arr[i].types[j]
                    )) {
                        const component = field === 'country' ? arr[i].short_name : arr[i].long_name
                        return component;
                }
            }
        }
        console.warn(field + ' not found in address.');
        return undefined;
    }

    const getAddress = () => {
        let address = `${getField('route')} ${getField('street_number') || ''}, ${getField('sublocality_level_1')}, ${getField('locality')}, ${getField('administrative_area_level_1')}, ${getField('country')}`
        address = address.replace(/\s,/,'');
        return address.indexOf('undefined') === -1 ? address : placeArr.formatted_address;
    }
    const
        latValue = isNaN(placeArr.geometry.location.lat) ? placeArr.geometry.location.lat() : placeArr.geometry.location.lat,
        lngValue = isNaN(placeArr.geometry.location.lng) ? placeArr.geometry.location.lng() : placeArr.geometry.location.lng;
    return {
        name: placeArr.name,
        address: placeArr.formatted_address,
        shortAddress: `${getField('route')} ${getField('street_number') || ''}`,
        lat: latValue,
        lng: lngValue,
        zipCode: getField('postal_code'),
        neighborhood: getField('sublocality_level_1'),
        city: getField('locality'),
        state: getField('administrative_area_level_1'),
        country: getField('country'),
    };
};

const createMapSnapshot = async (storeId, loc) => {
    const url = `https://maps.googleapis.com/maps/api/staticmap?center=` +
      `${loc.lat},${loc.lng}&markers=color:red|${loc.lat},${loc.lng}&size=500x300` +
      `&zoom=16&&maptype=roadmap&key=${process.env.REACT_APP_GOOGLE_API}`;

      fetch(url).then(async (pic) => {
      return pic.blob();
    })
      .then(async res => {
        s3.put(`store/${storeId}/loc.jpg`,
          res, {
          contentType: 'image/png',
          AWSS3: {
            bucket: awsmobile.aws_user_files_s3_bucket,
            region: awsmobile.aws_user_files_s3_bucket_region,
          }
        }).then(result => {return result;})
         .catch(err => console.error(err));
      });
  }

  const StoresMap = (props) => {
    const { center, zoom, height, onDragEnd } = props;

    useEffect(() => {
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API);
    }, []);

    const AsyncMap = withGoogleMap(() => (
        <GoogleMap
            defaultZoom={zoom}
            defaultCenter={{ lat: center.lat, lng: center.lng }}
        >
            <Marker
                name={'Ubicación'}
                draggable={true}
                onDragEnd={onDragEnd}
                position={{ lat: center.lat, lng: center.lng }}
            />
        </GoogleMap>
    ));

    return (
        <AsyncMap
            loadingElement={<div style={{ height: `${height}` }} />}
            containerElement={<div style={{ height: `${height}` }} />}
            mapElement={<div style={{ height: `${height}` }} />}
        />
    );
};

export { StoresMap, getLocData, onDragEnd, createMapSnapshot };
