import { API } from 'aws-amplify';
import { API_INVOICE } from '../constants';

const updateOrderStatus = async(invoiceRecordKey,newStatus) => {
    try{
        const result = await API.put(API_INVOICE, `/invoices/${invoiceRecordKey}`, {
            body: {
                status: newStatus
            }
        })
        return {
            success: result.success
        }
    }
    catch(error) {
        throw new Error(error);
    }
}

export default updateOrderStatus